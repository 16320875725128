.drag-list-item {

    background-color: rgb(255,255,255);
    border: 1px solid transparent;

    &.dragging-item {
        background-color: rgb(236, 236, 236);
    }

   &.active-item {
     border: 1px solid var(--apella-green);   
    }

}



