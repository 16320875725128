$hover-color: grey;

#main-drop-box {
    &.being-dragged {
        .section-drop-container {
            background-color: rgba(0,0,0,.2);
            border: 1px solid lightgrey;
        }
    }
}

.widget-container {
    border-width: 1px;
    border-style: dashed;
    border-color: transparent;
    transition: border-color 0.2s;
    position: relative;
    border-color: transparent;


    &.hovered {
        border-color: $hover-color;
    }

    &.active-widget,
    &.active-widget &-controls {
        border-style: solid;
        border-color: var(--apella-green);
    }

    &-controls {
        border-width: 1px;
        border-style: dashed;
        border-color: $hover-color;
        transition: border-color 0.2s;
        z-index: 50;
        background-color: white;
        border-bottom: 0;
        position: absolute;
        top: -41px;
        right: 50%;
        /* Position at the middle */
        transform: translateX(50%);
        /* Adjust position */

    }

    &.active-widget {
        .section-drop-container {
            .widget-container {
                &-controls {
                    border: none;
                    background-color: transparent;

                    top: 0;
                    right: 0;
                    /* Position at the middle */
                    transform: none;

                    svg {
                        color: $hover-color;
                    }
                }
            }
        }
    }

    .section-drop-container {
        .widget-container {
            //padding-top: 2rem;
            &-controls {
                border: none;
                background-color: transparent;
                color: black;
                top: 0;
                right: 0;
                opacity: 0.5;
                /* Position at the middle */
                transform: none;

                &:hover {
                    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
                    background-color: rgba(255,255,255,1);
                    opacity: 1;
                    svg {
                        &:hover {
                            color: #ea7c15;
                        }
                    }
                }

                svg {
                    color: $hover-color;
                }
            }
        }
        
        &.dragging-over {
            background-color: darkgrey;
        }
    }
}

/*.section-drop-container {
    background-color: grey;
}*/

.widget-container .widget-container .widget-container-controls {
    z-index: 100;
}