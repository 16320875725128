/* scanner */

.scanner {
    .eventlist .event {
      background-color: #006e55;
      color: #fff;
      line-height: 27.5px;
      padding: 1rem;

      &:hover {
        background-color: #107e65;
      }
    }

    .scan-button {
        background-color: #006e55;
      color: #fff;
    }
  }