.slider {
    height: 100%;
    background-color: orange;
}

/*.slider .item {
    width: 100%;
}*/

.slick-track {
    display: flex !important;
    align-items: center;

}

.slick-initialized .slick-slide {
    max-height: 100%;
}

.slick-slide {
    text-align: center;
    max-height: 100%;

    &>div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            box-sizing: border-box;
            padding: 1rem;
            max-height: 100%;
            max-width: 100%;
            margin: 0 auto;
        }
    }
}