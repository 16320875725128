.login-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  height: 300px;
  position: relative;
}

.applogo {
  text-align: center;
  height: 50px;

  div {
    font-weight: 400;
    font-size: 32px;
  }
}
