:root {
  --apella-green: #006e55;
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  /*overflow: hidden;*/
}

body {
  font-variant-numeric: tabular-nums;
}
.app {
  display: flex;
  position: relative;
  overflow: hidden;
}
.content {
  align-items: stretch;
  display: flex;
  flex-flow: column;
  flex: 1;
}
.leftToolbar {
  background-color: #006e55;
  color: #fff;
  width: 60px;
  padding: 0px;
}
.leftToolbar .selected,
.leftToolbar .selected:hover {
  background-color: #fff;
  color: #006e55;
}
.leftToolbar button .titlebar {
  background-color: #006e55;
  color: #fff;
}
.titlebar p {
  line-height: 2.5;
  font-weight: 500;
}
.bottombar {
  background-color: #ddd;
}
.topToolbar {
  height: 60px;
  display: flex;
  flex-flow: row;
  background-color: #fff;
  /*background-color: #373b3e;*/
}
.MuiButtonBase-root.dashboardButton {
  height: 60px;
  padding: 8px;
  gap: 8px;
  width: 250px;
  background-color: #fff;
  justify-content: flex-start;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.dashboardButton svg {
  color: #006e55;
}
.dashboardButton .smallermodifier {
  color: #fff;
}
.ui-widget {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.MuiButtonBase-root.dashboardButton.action {
  background-color: #006e55;
  color: #fff;
}
.dashboardButton.action svg {
  color: #fff;
}
.dashboardButton.gotowebinar svg {
  color: #00bff2;
}
.MuiButtonBase-root.dashboardButton:hover {
  background-color: #eee;
}
.MuiButtonBase-root.dashboardButton.action:hover {
  background-color: #107e65;
}
.MuiButtonBase-root.dashboardButton.action .modifierbg {
  background-color: #006e55;
}
.stackedIcon {
  display: inline-block;
  position: relative;
}
.stackedIcon .stack {
  position: absolute;
  top: 0px;
  left: 0px;
}
.stackedIcon .modifier {
  position: absolute;
  font-size: 1rem;
  right: 0px;
  bottom: 0px;
  width: 1rem;
  height: 1rem;
}
.stackedIcon .modifierbg {
  background-color: #ddd;
  border-radius: 100px;
}

.stackedIcon .smallermodifier {
  position: absolute;
  font-size: 0.5rem;
  right: 0.25rem;
  bottom: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
}

.todoevent {
  font-weight: 500;
}
.todotask {
  color: #ea7c15;
  font-weight: 500;
}
.titlebar,
.titlebar label,
.subtitlebar,
.faqtitle {
  color: #006e55;
}
.subtitlebar p {
  font-size: 0.75rem;
  font-weight: 500;
}
.titlebar label {
  font-size: 0.75rem;
  font-weight: 500;
}
.titlebar {
  padding: 0px 8px;
}
.events .event {
  background-color: #006e55;
  color: #fff;
  line-height: 27.5px;
  padding: 0px 10px;
}
.events .event div {
  line-height: 34px;
}
.events .event span {
  line-height: 34px;
}
.events .eventdetails svg {
  position: relative;
  top: 4px;
}
.events .event:hover {
  background-color: #107e65;
}

.events .eventdescription {
  padding: 10px;
  border: 1px solid #eee;
}
.events .category {
  line-height: 24px;
  display: flex;
  gap: 4px;
  color: #ea7c15;
  font-weight: 500;
}
.tabs .MuiTabs-flexContainer {
  background-color: #006e55;
}
.tabs .MuiTab-root {
  color: #fff;
}
.tabs .MuiTab-root.Mui-selected {
  background-color: #fff;
  color: #006e55;
}
.tabs .MuiTabs-indicator {
  height: 4px;
}
.topToolbar .eventdetails svg {
  position: relative;
  color: #006e55;
  top: 4px;
  margin-left: 10px;
}
.topToolbar .eventdetails .eventType {
  margin-left: 10px;
  padding: 8px;
  border-radius: 10px;
  background-color: #ea7c15;
  color: #fff;
}
.fieldlabel p {
  padding-top: 5px;
}
.switchlabel {
  padding-top: 9px;
  padding-right: 8px;
}
.subtabbar {
  background-color: #1f4c42;
}
.subtabbar .MuiTab-root {
  color: #fff;
}
.subtabbar .MuiTab-root.Mui-selected {
  background-color: #fff;
  color: #ea7c15;
}
.subtabbar .MuiTabs-indicator {
  background-color: #fff;
  height: 0px;
}
#errorpage {
  text-align: center;
}

.tabs {
  min-height: 60px;
}
.tabs button {
  min-height: 60px;
}

.collectionedit .list {
  border-right-color: #006e55;
  border-right-style: solid;
  border-right-width: 8px;
}

.cliclalist .moduleEnd {
  border-style: solid;
  border-width: 1px;
  border-color: #006e55;
  border-top-style: none;
  border-top-width: 0px;
}

.cliclalist .module {
  border-style: solid;
  border-width: 1px;
  border-color: #006e55;
  border-bottom-style: none;
  border-bottom-width: 0px;
}
.cliclalist .module.active {
  border-color: #ea7c15;
  background-color: #ea7c15;
  color: #fff;
}
.cliclalist .moduleEnd.active {
  border-color: #ea7c15;
  background-color: #ea7c15;
}
.cliclalist .cliclalistitembutton:first-child {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
}
.cliclalist .cliclalistitembutton {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  background-color: #c2e8de;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #006e55;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #006e55;
  color: #000;
}
.cliclalist .cliclalistitembutton.active {
  background-color: #ea7c15;
  color: #fff;
}
.title {
  font-size: 1.5rem;
  margin-bottom: 3px;
}
.divider {
  height: 3px;
  background-color: #000;
  margin: 10px 0px;
}
.fieldtypelist input,
.fieldtypelist label {
  cursor: grab;
}
.errormessage {
  color: #a82626;
}
.eventtoggles {
  background-color: #eee;
  padding: 10px;
}
.badge .MuiBadge-badge {
  background-color: #1f4c42;
  color: #fff;
}
.sessionslist .module .modulebtn:hover {
  background-color: #107e65;
}
.sessionslist .module.active .modulebtn {
  background-color: #ea7c15;
}

.sessionslist .session {
  background-color: #006e55;
  border-radius: 4px;
  color: #fff;
  width: 100%;
}
.sessionslist .session:hover {
  background-color: #107e65;
}
.sessionslist .session.active {
  background-color: #ea7c15;
}
