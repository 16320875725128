.scanner-info-list li {
  padding: 0;
}

.small-modal {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background-color: #fff;
  border: 1px solid #bbbbbb;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  max-width: 90%;
  box-sizing: border-box;
  overflow: hidden;

  &:focus-visible {
    outline: none;
  }
}
