.dropbox-container {
    flex: 1;
    background-color: white;
    display: flex;
    margin: 0 10%;
    padding: 1rem;
    flex-direction: row;

    .dropbox {
        border: 3px dashed;
        padding: 2rem;
        background-color: white;
        flex: 1;
        margin: 1rem;
        overflow-y: scroll;   
    }
}

.dropbox-container .dropbox {
    border-color: transparent;
}

.dropbox-container.highlight-dropbox-container .dropbox {
    border-color: rgb(223, 220, 220);
    transition: border-color 0.2s;
}

.registration-field {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    border-width: 1px;
        border-style: solid;
        border-color: transparent;
    &.active-field {
        border-color: rgb(233, 233, 233);
        transition: border-color .5s;
    }

    & .fieldlabel p {
        padding-top: 0;
    }

    & .MuiIconButton-root {
        padding-top: 0;
    }
}

.website-side-panel {
    box-sizing:border-box;
    padding: 1rem;

    & &-controls {
        display: flex;
        flex-direction: row;
        justify-content: end;
    }
}



