// Define variables for reusable values
$primaryColor: #006e55;
$backgroundColor: #f0f0f0;
$secondaryColor: #ea7c15;
$gridGap: 1rem;

.calendar-root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.calendar-controls {
  color: $primaryColor;

  .calendar-month {
    text-transform: uppercase;
    font-weight: bold;
  }
}

.days-of-week,
.days-grid {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.days-of-week {
  border-bottom: 5px solid $primaryColor;

  div {
    padding: $gridGap 0 0 0;
    color: $primaryColor;
    font-weight: bold;
  }
}

.days-grid {
  flex: 1;
  position: relative;
  background-color: $backgroundColor;
}

.day-grid-item-container {
  position: relative;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: column;

  &.today, &.current-month.today {
    background: linear-gradient(180deg, lighten($secondaryColor, 10%) 100%, $secondaryColor 0%);
    transition: backgroundColor 2s;
  }


  & .day-grid-item-header {
    padding: $gridGap;
    flex-shrink: 0;
    font-weight: bold;
    color: #868686;

    /*&.today {
      border-bottom: 5px solid $primaryColor;
    }*/
  }

  
  .event-item {
    padding: 1rem;
    background-color: transparent;
    box-sizing: border-box;
    border: 1px solid #b0b0b0;
    margin-top: 1rem;
    background-color: #006e55;
    color: white;
    transition: background-color 0.5s;

    &:not(.first-day):not(.last-day):not(.single-day) {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      clip-path: inset(-20px 0px -5px 0px);
    }

    &.first-event {
      margin-top: 0;
    }

    &.first-day {
      z-index: 500;
      border-left: 1px solid #b0b0b0 !important;
      margin-left: 1rem;
      border-radius: 10px 0 0 10px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }
  
    &.last-day {
      border-right: 1px solid #b0b0b0 !important;
      margin-right: 1rem;
      border-radius: 0 10px 10px 0;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      clip-path: inset(-5px 0px -5px 0px);
    }
  
    &:not(.single-day) {
      border-left: none;
      border-right: none;
    }

    &.single-day {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 0;
      border-radius: 10px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.20);
    }

    &.first-day,
    &.last-day,
    &.single-day, 
    &:not(.first-day):not(.last-day):not(.single-day) {
      &.selected-event {
        background-color: $secondaryColor !important;
      }
    }
  }

  &.current-month {
    background-color: white;

    & .day-grid-item-header {
      color: $primaryColor;
      margin-bottom: 1rem;

      &.today {
        background-color: $secondaryColor;
        color: white;
      }
    }
  }
}

.today {
  .day-content-wrapper {
    &.selected {
      background: linear-gradient(180deg, darken($secondaryColor, 10%) 100%, $secondaryColor 0%);
    } 
}
}

.day-content-wrapper {
  &.selected {
    background-color: #e1e1e1;
  } 


 &:hover {
  cursor: pointer;
 } 
}

.day-grid-item-container > .day-content-wrapper {
  flex: 1;
  min-height: 0;
  position: relative;
}

.calendar-context-menu {
  position: absolute;
  z-index: 500;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
}